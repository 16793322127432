import Sb from "../abstract/StatefulBehavior";

export default class OpenAccordionOnLoad extends Sb {
  constructor(el, props, refs) {
    super();

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.update();
  }

  update = () => {
    // Set this dialog open if the id matches the current hash value
    const hash = window?.location.hash?.substring(1);
    const isOpen = hash === this.props.id;

    if (isOpen) {
      this.el.setAttribute("open", isOpen);

      try {
        setTimeout(() => {
          const { anchor } = this.refs;
          const scrollTop = window.pageY;
          const anchorTop = anchor.getBoundingClientRect().top;
          window.scrollTo({ top: anchorTop + scrollTop, behavior: "smooth" });
        }, 100);
      } catch {
        console.error("Failed to scroll to accordion element.");
      }
    }
  };
}
